import logo from "./logo.webp"

const  clientData = {
   client_entity: 17,
   attorney_firm: 'cgcpllc.com',
    attorney_name: 'Brent Gay',
    attorney_number: '601-469-1414',
    attorney_email: 'brent@cgcpllc.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_chance_gay_cieglo+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#AF864D',
    siteLink: 'http://cgcpllc.com',
    logo
}

export default clientData